<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <ecommerce-medal :data="data.topAgent" />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <ecommerce-statistics :data="data.statisticsItems" />
      </b-col>

      <b-col
        xl="12"
        md="12"
      >
        <b-overlay
          :show="showLoading"
          rounded="sm"
          variant="white"
          opacity="0.50"
        >
          <b-card no-body>
            <b-card-header>
              <b-card-title>Filter Data</b-card-title>
              <b-card-text class="font-small-2 mb-0">
                <b-button
                  variant="flat-danger"
                  @click.prevent="resetFilters"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Reset Filters</span>
                </b-button>
              </b-card-text>
            </b-card-header>

            <b-card-body>
              <b-row>
                <b-col
                  xl="3"
                  md="6"
                >
                  <label>From</label>
                  <b-form-datepicker
                    v-model="fromDate"
                    size="sm"
                    local="en"
                  />
                </b-col>
                <b-col
                  xl="3"
                  md="6"
                >
                  <label>To</label>
                  <b-form-datepicker
                    v-model="toDate"
                    size="sm"
                    local="en"
                  />
                </b-col>
                <b-col
                  xl="3"
                  md="6"
                >
                  <label>Sales Advisor</label>
                  <b-form-group>
                    <v-select
                      v-model="selectedAgent"
                      class="select-size-sm"
                      :options="agentsList"
                      label="name"
                      :reduce="name => name.id"
                      placeholder="Select Agent"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  xl="3"
                  md="6"
                >
                  <label>Product Type</label>
                  <b-form-group>
                    <v-select
                      v-model="selectedType"
                      class="select-size-sm"
                      :options="insuranceTypeList"
                      label="title"
                      :reduce="title => title.id"
                      placeholder="Select Type"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>

          </b-card>
        </b-overlay>
      </b-col>

      <b-col
        v-if="brokersChartData !== []"
        xl="12"
        md="12"
      >
        <b-overlay
          :show="showLoading"
          rounded="sm"
          variant="white"
          opacity="0.50"
        >
          <b-card no-body>
            <b-card-header>
              <b-card-title>Broker / Ambassador</b-card-title>
              <b-card-text class="font-small-2 mb-0">
                <b-button
                  variant="flat-primary"
                  @click.prevent="ExportChartFunction('brokers_chart')"
                >
                  <feather-icon
                    icon="ShareIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Export</span>
                </b-button>
              </b-card-text>
            </b-card-header>

            <b-card-body>
              <ejs-chart
                id="brokers_chart"
                style="display:block"
                theme="dark"
                align="center"
                :primary-x-axis="primaryXAxis"
                :chart-area="chartArea"
                :palettes="palettes"
                :legend-settings="legendSettings"
                :width="width"
                :enable-smart-labels="enableSmartLabels"
                :text-render="onTextRender"
              >
                <e-series-collection>
                  <e-series
                    :data-source="brokersChartData['brokers']"
                    type="Line"
                    x-name="x"
                    y-name="y"
                    :name="'Brokers(' + brokersChartData['totalBrokersCount'] + ')'"
                    width="3"
                    :marker="markerBottom"
                  />
                  <e-series
                    :data-source="brokersChartData['ambassadors']"
                    type="Line"
                    x-name="x"
                    y-name="y"
                    :name="'Ambassadors(' + brokersChartData['totalAmbassadorsCount'] + ')'"
                    width="3"
                    :marker="markerTop"
                  />
                  <e-series
                    :data-source="brokersChartData['total']"
                    type="Line"
                    x-name="x"
                    y-name="y"
                    :name="'Total(' + brokersChartData['totalCount'] + ')'"
                    width="3"
                    :marker="markerTop"
                  />
                </e-series-collection>
              </ejs-chart>
            </b-card-body>

          </b-card>
        </b-overlay>
      </b-col>

      <b-col
        xl="12"
        md="12"
      >
        <b-overlay
          :show="showLoading"
          rounded="sm"
          variant="white"
          opacity="0.50"
        >
          <b-card no-body>
            <b-card-header>
              <b-card-title>Leads</b-card-title>
              <b-card-text class="font-small-2 mb-0">
                <b-button
                  variant="flat-primary"
                  @click.prevent="ExportChartFunction('leads_chart')"
                >
                  <feather-icon
                    icon="ShareIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Export</span>
                </b-button>
              </b-card-text>
            </b-card-header>

            <b-card-body>
              <ejs-chart
                id="leads_chart"
                :primary-x-axis="primaryXAxis"
                :legend-settings="legendSettings"
                :palettes="barPalette"
                :enable-smart-labels="enableSmartLabels"
                :text-render="onTextRender"
              >
                <e-series-collection>
                  <e-series
                    :data-source="leadsBarChartData['series']"
                    type="Column"
                    x-name="x"
                    y-name="y"
                    column-width="0.35"
                    :corner-radius="{
                      topLeft: 10, topRight: 10
                    }"
                    :name="'leads(' + leadsBarChartData['leadsCount'] + ')'"
                    :marker="markerTop"
                  />
                </e-series-collection>
              </ejs-chart>
            </b-card-body>

          </b-card>
        </b-overlay>
      </b-col>

      <b-col
        v-if="tiaLivesChartData !== []"
        xl="12"
        md="12"
      >
        <b-overlay
          :show="showLoading"
          rounded="sm"
          variant="white"
          opacity="0.50"
        >
          <b-card no-body>
            <b-card-header>
              <b-card-title>TIA (Lives)</b-card-title>
              <b-card-text class="font-small-2 mb-0">
                <b-button
                  variant="flat-primary"
                  @click.prevent="ExportChartFunction('tia_lives_chart')"
                >
                  <feather-icon
                    icon="ShareIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Export</span>
                </b-button>
              </b-card-text>
            </b-card-header>

            <ejs-chart
              id="tia_lives_chart"
              style="display:block"
              theme="dark"
              align="center"
              :primary-x-axis="primaryXAxis"
              :legend-settings="legendSettings"
              :chart-area="chartArea"
              :width="width"
              :palettes="palettes"
              :enable-smart-labels="enableSmartLabels"
              :text-render="onTextRender"
            >
              <e-series-collection>
                <e-series
                  :data-source="tiaLivesChartData['series']"
                  type="Line"
                  x-name="x"
                  y-name="y"
                  :name="'TIA Lives(' + tiaLivesChartData['tiaTotal'] + ')'"
                  width="3"
                  :marker="markerTop"
                />
              </e-series-collection>
            </ejs-chart>

          </b-card>
        </b-overlay>
      </b-col>

      <b-col
        v-if="tiaPremiumChartData !== []"
        xl="12"
        md="12"
      >
        <b-overlay
          :show="showLoading"
          rounded="sm"
          variant="white"
          opacity="0.50"
        >
          <b-card no-body>
            <b-card-header>
              <b-card-title>TIA (Annual Premium GBP)</b-card-title>
              <b-card-text class="font-small-2 mb-0">
                <b-button
                  variant="flat-primary"
                  @click.prevent="ExportChartFunction('tia_premium_chart')"
                >
                  <feather-icon
                    icon="ShareIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Export</span>
                </b-button>
              </b-card-text>
            </b-card-header>

            <ejs-chart
              id="tia_premium_chart"
              style="display:block"
              theme="dark"
              align="center"
              :primary-x-axis="primaryXAxis"
              :legend-settings="legendSettings"
              :chart-area="chartArea"
              :enable-smart-labels="enableSmartLabels"
              :text-render="onTextRender"
            >
              <e-series-collection>
                <e-series
                  :data-source="tiaPremiumChartData['series']"
                  type="Line"
                  x-name="x"
                  y-name="y"
                  :name="'TIA Annual Premium GBP(' + tiaPremiumChartData['tiaTotal'] + ')'"
                  width="3"
                  :marker="markerTop"
                />
              </e-series-collection>
            </ejs-chart>

          </b-card>
        </b-overlay>
      </b-col>

      <b-col
        xl="6"
        md="6"
      >
        <b-card>
          <b-card-title class="mb-1">
            Top 5 Brokers (Lives)
          </b-card-title>
          <b-card-sub-title class="mb-2">
            Most lives achieved by brokers
          </b-card-sub-title>

          <!--          <vue-apex-charts
            type="donut"
            height="350"
            :options="donutChart.chartOptions"
            :series="donutChart.series"
          />-->
          <ejs-accumulationchart
            id="top_five_brokers_chart"
            :legend-settings="donutLegendSettings"
          >
            <e-accumulation-series-collection>
              <e-accumulation-series
                :data-source="top5BrokersLivesChartData"
                x-name="x"
                y-name="y"
                inner-radius="40%"
                :data-label="top5BrokersDataLabel"
                :point-color-mapping="pointColorMapping"
              />
            </e-accumulation-series-collection>
          </ejs-accumulationchart>
        </b-card>
      </b-col>

      <b-col
        xl="6"
        md="6"
      >
        <b-card>
          <b-card-title class="mb-1">
            Top 5 Brokers (Annual Premium)
          </b-card-title>
          <b-card-sub-title class="mb-2">
            Most TIA achieved by brokers
          </b-card-sub-title>

          <!--          <vue-apex-charts
            type="donut"
            height="350"
            :options="tiaDonutChart.chartOptions"
            :series="tiaDonutChart.series"
          />-->
          <ejs-accumulationchart
            id="top_five_brokers_premium_chart"
            :legend-settings="donutLegendSettings"
          >
            <e-accumulation-series-collection>
              <e-accumulation-series
                :data-source="top5BrokersPremiumChartData"
                x-name="x"
                y-name="y"
                inner-radius="40%"
                :data-label="top5BrokersDataLabel"
                :point-color-mapping="pointColorMapping"
              />
            </e-accumulation-series-collection>
          </ejs-accumulationchart>
        </b-card>
      </b-col>

      <b-col
        v-if="supportTrackerRadialBar.series.length > 0"
        xl="6"
        md="6"
      >
        <b-card no-body>
          <!-- title and dropdown -->
          <b-card-header class="pb-0">
            <b-card-title>Meeting Analysis</b-card-title>
            <b-dropdown
              no-caret
              right
              text="Current"
              variant="transparent"
              class="chart-dropdown"
              toggle-class="p-50"
              size="sm"
            >
              <b-dropdown-item>
                Current
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>
          <!--/ title and dropdown -->

          <b-card-body>
            <b-row>
              <b-col
                sm="2"
                class="d-flex flex-column flex-wrap text-center"
              >
                <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
                  {{ data.meetingsAnalysis.allMeetings }}
                </h1>
                <b-card-text>Meetings</b-card-text>
              </b-col>

              <!-- chart -->
              <b-col
                sm="10"
                class="d-flex justify-content-center"
              >

                <!-- apex chart -->
                <vue-apex-charts
                  type="radialBar"
                  height="270"
                  :options="supportTrackerRadialBar.chartOptions"
                  :series="supportTrackerRadialBar.series"
                />
              </b-col>
              <!--/ chart -->
            </b-row>

            <!-- chart info -->
            <div class="d-flex justify-content-between">
              <div class="text-center">
                <b-card-text class="mb-50">
                  Upcoming Meetings
                </b-card-text>
                <span class="font-large-1 font-weight-bold">{{ data.meetingsAnalysis.upComingMeetings }}</span>
              </div>
              <div class="text-center">
                <b-card-text class="mb-50">
                  Invalid / Lost
                </b-card-text>
                <span class="font-large-1 font-weight-bold">{{ data.meetingsAnalysis.invalidMeetingsCount }}</span>
              </div>
              <div class="text-center">
                <b-card-text class="mb-50">
                  Closed
                </b-card-text>
                <span class="font-large-1 font-weight-bold">{{ data.meetingsAnalysis.closedMeetings }}</span>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardTitle, BCardSubTitle, BCardBody, BCardText, BCardHeader, BDropdown, BDropdownItem, BButton,
  BFormDatepicker, BFormGroup, BOverlay,
} from 'bootstrap-vue'

// import { getUserData } from '@/auth/utils'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { mapActions, mapGetters } from 'vuex'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Vue from 'vue'
import {
  ChartPlugin, LineSeries, Legend, DataLabel, Category, ColumnSeries, AccumulationChartPlugin, PieSeries, AccumulationDataLabel, AccumulationLegend, Export,
} from '@syncfusion/ej2-vue-charts'
import vSelect from 'vue-select'
import EcommerceMedal from './EcommerceMedal.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'

Vue.use(ChartPlugin)
Vue.use(AccumulationChartPlugin)

const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#BAD000',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series3: '#a4f8cd',
    series2: '#60f2ca',
    series1: '#2bdac7',
  },
}

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BCardTitle,
    BCardSubTitle,
    VueApexCharts,
    EcommerceMedal,
    EcommerceStatistics,
    BCardBody,
    BCardHeader,
    BCardText,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormDatepicker,
    vSelect,
    BOverlay,
  },
  data() {
    return {
      selectedAgent: null,
      fromDate: null,
      toDate: null,
      selectedType: null,
      showLoading: false,
      brokersChartData: [],
      tiaLivesChartData: [],
      tiaPremiumChartData: [],
      leadsBarChartData: [],
      top5BrokersLivesChartData: [],
      top5BrokersPremiumChartData: [],
      enableSmartLabels: true,
      top5BrokersDataLabel: {
        // eslint-disable-next-line no-template-curly-in-string
        visible: true, name: 'text', template: '<b>${Number(point.y).toLocaleString()}</b>', font: { fontWeight: 'bold', size: 16, color: '#fff' },
      },
      pointColorMapping: 'fill',
      palettes: ['#BAD000', '#78dbbe', '#E89841'],
      barPalette: ['#e8a55b'],
      primaryXAxis: {
        valueType: 'Category',
        edgeLabelPlacement: 'Shift',
        majorGridLines: { width: 0 },
      },
      chartArea: {
        border: {
          width: 0,
        },
      },
      markerTop: {
        visible: true,
        height: 10,
        width: 10,
        shape: 'Circle',
        dataLabel: {
          visible: true, position: 'Auto', angle: 20, font: { fontWeight: 'bold', size: 15, color: '#5e5873' },
        },
      },
      markerBottom: {
        visible: true,
        height: 10,
        width: 10,
        shape: 'Circle',
        dataLabel: { visible: true, position: 'Auto', font: { fontWeight: 'bold', size: 15, color: '#5e5873' } },
      },
      legendSettings: {
        visible: true,
        position: 'Top',
      },
      donutLegendSettings: {
        visible: true,
        position: 'Bottom',
        toggleVisibility: true,
      },
      width: '100%',
      data: {},
      option: {
        xAxisData: [],
        series: [],
      },
      tiaLivesOption: {
        xAxisData: [],
        series: [],
      },
      tiaAnnualPremiumOption: {
        xAxisData: [],
        series: [],
      },
      barOption: {
        xAxis: [
          {
            type: 'category',
            data: [],
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
          },
        ],
        grid: {
          left: '40px',
          right: '30px',
          bottom: '30px',
        },
        series: [],
      },
      donutChart: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: [
            chartColors.donut.series1,
            chartColors.donut.series5,
            chartColors.donut.series3,
            chartColors.donut.series2,
            chartColors.donut.series4,
          ],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: false,
                    fontSize: '1.5rem',
                    label: 'Khaled Ahmed',
                    formatter() {
                      return '31'
                    },
                  },
                },
              },
            },
          },
          labels: [],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
      tiaDonutChart: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: [
            chartColors.donut.series1,
            chartColors.donut.series5,
            chartColors.donut.series3,
            chartColors.donut.series2,
            chartColors.donut.series4,
          ],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: false,
                    fontSize: '1.5rem',
                    label: 'Khaled Ahmed',
                    formatter() {
                      return '31'
                    },
                  },
                },
              },
            },
          },
          labels: [],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
      supportTrackerRadialBar: {
        series: [],
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Closed Meetings'],
        },
      },
    }
  },
  provide: {
    chart: [LineSeries, Legend, DataLabel, Category, ColumnSeries, Export],
    accumulationchart: [PieSeries, AccumulationDataLabel, AccumulationLegend],
  },
  computed: {
    ...mapGetters({
      agentsList: 'users/agentsList',
      insuranceTypeList: 'insuranceTypes/insuranceTypeList',
    }),
    fromDateComputed() {
      return this.fromDate
    },
    toDateComputed() {
      return this.toDate
    },
    agentComputed() {
      return this.selectedAgent
    },
    typeComputed() {
      return this.selectedType
    },
  },
  watch: {
    fromDateComputed() {
      this.loadDashboardFunction()
    },
    toDateComputed() {
      this.loadDashboardFunction()
    },
    agentComputed() {
      this.loadDashboardFunction()
    },
    typeComputed() {
      this.loadDashboardFunction()
    },
  },
  async created() {
    this.showLoading = true
    await this.get({
      storeName: 'users',
      url: '/admin/users/agents',
      commitUrl: 'users/SET_AGENTS',
    })
    await this.get({
      storeName: 'insuranceTypes',
      url: '/admin/settings/insurance/types',
      commitUrl: 'insuranceTypes/SET_INSURANCE_TYPE',
    })
    this.selectedType = 1
    await this.loadDashboardFunction()
  },
  methods: {
    ...mapActions({
      get: 'get',
    }),
    onTextRender(args) {
      // eslint-disable-next-line no-param-reassign
      args.text = Number(args.text).toLocaleString()
    },
    resetFilters() {
      this.fromDate = null
      this.toDate = null
      this.selectedType = null
      this.selectedAgent = null
    },
    ExportChartFunction(chartID) {
      const chart1 = document.getElementById(chartID).ej2_instances[0]
      chart1.exportModule.export('PNG', 'Chart', null, [chart1])
    },
    async loadDashboardFunction() {
      let url = `/admin/dashboard?endDate=${new Date(Date.now()).toISOString()}`
      if (this.fromDate !== null) {
        url += `&startDate=${this.fromDate}`
      }
      if (this.toDate !== null) {
        url += `&endDate=${this.toDate}`
      }
      if (this.selectedAgent !== null) {
        url += `&agent=${this.selectedAgent}`
      }
      if (this.selectedType !== null) {
        url += `&type=${this.selectedType}`
      }
      this.showLoading = true
      await this.get({
        url,
      }).then(response => {
        this.data = response.data
        this.brokersChartData = response.data.brokersChart
        // this.option.xAxisData = response.data.last12Months
        this.barOption.xAxis[0].data = response.data.last12Months
        /* this.tiaLivesOption.xAxisData = response.data.last12Months
        this.tiaLivesOption.series = response.data.tiaLivesChart.series */
        this.tiaLivesChartData = response.data.tiaLivesChart
        this.tiaPremiumChartData = response.data.tiaPremiumChart
        this.leadsBarChartData = response.data.leadsChart
        /* this.tiaAnnualPremiumOption.xAxisData = response.data.last12Months
        this.tiaAnnualPremiumOption.series = response.data.tiaPremiumChart.series */
        // this.option.series = response.data.brokersChart.series
        this.barOption.series = response.data.leadsChart.series
        this.supportTrackerRadialBar.series[0] = response.data.meetingsAnalysis.completedMeetingPercentage
        this.top5BrokersLivesChartData = []
        this.top5BrokersPremiumChartData = []
        response.data.top5BrokersLives.names.forEach(element => {
          this.top5BrokersLivesChartData.push({
            // eslint-disable-next-line radix
            x: `${element.broker.name} ${element.broker.last_name}`, y: parseInt(element.total), text: parseInt(element.total),
          })
        })
        response.data.top5BrokersPremium.names.forEach(element => {
          this.top5BrokersPremiumChartData.push({
            // eslint-disable-next-line radix
            x: `${element.broker.name} ${element.broker.last_name}`, y: parseInt(element.total), text: parseInt(element.total),
          })
        })
        this.showLoading = false
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error,
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
.echarts {
  width: 100%;
}
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
